import 'reflect-metadata';
// Import polyfills - has to be first - before everything else
import '../node_modules/ui-core/plugins/polyfills/js/polyfill.object.values';

// Import components
import '../node_modules/ui-core/utility/js/namespace.js';

// Import variables
import '../variables/js/imports.js';

//import './sourceJs.js'
import 'jquery';

import '../node_modules/ui-core/plugins/jquery/jquery-migrate.js';

import '../node_modules/ui-core/utility/js/utils'; // basic UX3 useful functions

import '../node_modules/ui-core/utility/js/tests'; // basic UX3 features tests

import '../node_modules/ui-core/utility/js/jquery.selector.focusable';
import '../node_modules/ui-core/utility/js/no-focusable-svg';
import '../node_modules/ui-core/utility/js/observe-dom.js';
import '../node_modules/ui-core/utility/js/addToAnyEnhancement.ts';

import '../node_modules/ui-core/utility/ab-logger/js/ab-logger';

//TODO: When LIT-157794 will be fixed, uncomment next line
// import '../node_modules/ui-core/plugins/jquery-ui/jquery-ui.min.js';

/*JCF plugin*/
import '../node_modules/ui-core/plugins/custom-form/js/jcf.js';
import '../node_modules/ui-core/plugins/custom-form/js/jcf.select.js';
import '../node_modules/ui-core/plugins/custom-form/js/jcf.file.js';
import '../node_modules/ui-core/plugins/custom-form/js/jcf.button.js';
import '../node_modules/ui-core/plugins/custom-form/js/jcf.checkbox.js';
import '../node_modules/ui-core/plugins/custom-form/js/jcf.number.js';
import '../node_modules/ui-core/plugins/custom-form/js/jcf.radio.js';
import '../node_modules/ui-core/plugins/custom-form/js/jcf.range.js';
import '../node_modules/ui-core/plugins/custom-form/js/jcf.scrollable.js';
import '../node_modules/ui-core/plugins/custom-form/js/jcf.textarea.js';
import '../node_modules/ui-core/plugins/custom-form/js/jcf.start.js';

import '../node_modules/ui-core/plugins/panzoom/jquery.panzoom.js'; // Todo: find how to prevent AMD define()

import '../node_modules/ui-core/plugins/jquery-ui/jquery-ui.js';

import '../node_modules/ui-core/plugins/jquery-ui/jquery.ui.touch-punch.min.js';

import '../node_modules/ui-core/plugins/touchSwipe/jquery.touchSwipe.js'; // Todo: find how to prevent AMD define()

//import '../node_modules/ui-core/plugins/literatum/imports'

import AdvancedSearch from '../node_modules/ui-core/widgets/advanced-search/js/advanced-search';
import QuickSearch from '../node_modules/ui-core/widgets/quick-search/js/quick-search';
import IpRanges from '../node_modules/ui-core/widgets/ip-ranges/js/ip-ranges';

const advancedSearchEl = document.querySelector('.advanced-search');
if (advancedSearchEl) {
    UX.searchFieldsCtrl = new AdvancedSearch(advancedSearchEl).initialize();
}

const quickSearch = document.querySelector('.quick-search');
if (quickSearch) {
    UX.quickSearch = new QuickSearch(quickSearch).initialize();
}

const ipRanges = document.querySelector('.ipRangesWidget');
if (ipRanges) {
    UX.ipRanges = new IpRanges(ipRanges);
    UX.ipRanges.initialize();
}

import '../node_modules/ui-core/plugins/scrollo/js/scrollo.js';
import '../node_modules/ui-core/plugins/enquireJs/js/enquire.js';
import '../node_modules/ui-core/plugins/controller/js/controller.js';
import '../node_modules/ui-core/plugins/stickyElements/js/stickyElements';
import '../node_modules/ui-core/plugins/auto-hide-bar/js/auto-hide-bar.js';
import '../node_modules/ui-core/plugins/polyfills/js/resizeObserverPolyfill/dist/ResizeObserver.js';

import '../node_modules/ui-core/widgets/holdings/js/holdings.js';

import '../basic/js/imports.js';
import '../components/js/imports.js';
import '../widgets/js/imports.js';
import '../templates/js/imports.js';

import '../node_modules/ui-core/plugins/controller/js/enquireIt.js'; // important: keep it always last
